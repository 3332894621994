/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Geocode from 'react-geocode';

import IncidentLayout from '../components/Branding/IncidentLayout';
import Map from '../components/Map';
import { titleContext } from '../contexts/TitleContext';
import { breadCrumbsContext } from '../contexts/BreadCrumbsContext';
import DisasterRecoveryTable from '../components/Tables/DisasterRecovery';
import { incidentsContext } from '../contexts/IncidentsContext';
import PressItem from '../components/PressReleases/PressItem';

Geocode.setApiKey(`AIzaSyBRbdKmyFU_X9r-UVmsapYMcKDJQJmQpAg`);
Geocode.setLocationType('ROOFTOP');

const headCells = [
  {
    id: 'dataid',
    label: '',
  },
  {
    id: 'Category',
    label: 'Type',
  },
  {
    id: 'LocationName',
    label: 'Location',
  },
  {
    id: 'Location',
    label: 'Address',
  },
  {
    id: 'Details',
    label: 'Additional Details',
  },
];

export default function DsiasterRecovery({ incidentIndex }) {
  const { updatePageTitle, updatePageHeading } = useContext(titleContext);
  const { pushBreadCrumbs } = useContext(breadCrumbsContext);
  const { incidents } = useContext(incidentsContext);
  const [center, setCenter] = useState({ lat: 29.651634, lng: -82.324829 });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [disasterRecoveryLocationData, setDisasterRecoveryLocationData] = useState([]);
  const [disasterRecoveryUpdates, setDisasterRecoveryUpdates] = useState([]);

  let url;
  
  if(process.env.REACT_APP_ENV == 'local'){
    url = process.env.REACT_APP_LOCAL_URL
  } else {
    url = 'https://api.alachuacounty.us/incidents-api'
  }

  const getDisasterRecoveryCentersLocationData = async () => {
    try {
      const test = await axios.get(`${url}/distributionLocations/activeDisasterRecoveryLocations`);

      for (let index in test.data[0]) {
        const response = await Geocode.fromAddress(test.data[0][index].Location);
        if (response.results[0]) {
          const { lat, lng } = response.results[0].geometry.location;
          test.data[0][index].latitude = lat;
          test.data[0][index].longitude = lng;
          test.data[0][index].dataid = test.data[0][index].MondayID;
          test.data[0][index].label= test.data[0][index].LocationName;
          test.data[0][index].address = test.data[0][index].Location;
        }
      }

      setDisasterRecoveryLocationData(test.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMapCenter = (lat, lng, id) => {
    setCenter({ lat: parseFloat(lat), lng: parseFloat(lng) });
    updateSelectedMarker(id);
  };

  const updateSelectedMarker = (id) => {
    setSelectedMarker(id);
  };

  useEffect(() => {
    getDisasterRecoveryCentersLocationData();
    updatePageTitle(`${incidents[incidentIndex].name} | Disaster Recovery`);
    updatePageHeading(incidents[incidentIndex].name);
    if (incidents && incidents.length && incidents[0].updates.disasterRecoveryUpdates){
    setDisasterRecoveryUpdates(incidents[incidentIndex].updates.disasterRecoveryUpdates);
    }
    pushBreadCrumbs({
      crumb: incidents[incidentIndex].name,
      link: `/incidents/${incidents[incidentIndex].urlName}/`,
    });
  }, []);

  return (
    <IncidentLayout title='Recovery Resources'>
      <div id='disasterRecoveryMap'>
      <Grid container>
        {disasterRecoveryLocationData && disasterRecoveryLocationData.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Map data={disasterRecoveryLocationData} center={center} selectedMarker={selectedMarker} />
            </Grid>
            <Grid item xs={12}>
              <DisasterRecoveryTable headCells={headCells} rows={disasterRecoveryLocationData} updateMapCenter={updateMapCenter} />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              There are currently no centers open. This page will be updated as the incident progresses. Please check again later for recovery resources.
            </Typography>
          </Grid>
        )}
      </Grid>
      </div>
      <div id='disasterRecoveryBlurb'>
      <Grid>
      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              Disaster Recovery Updates
            </Typography>
      </Grid>  
      <Grid container xs={12} paddingBottom={6} justifyContent='flex-start'>
        {disasterRecoveryUpdates && disasterRecoveryUpdates.length > 0 ? (

          disasterRecoveryUpdates.map((data, index) => (
            <PressItem data={data} spacing={10} expanded={true} justifyContent={'flex-start'} index={index} />
          ))
        ) : (
          <Typography variant='h6'>No Disaster Recovery Updates</Typography>
        )}
      </Grid>
      </div>
    </IncidentLayout>
  );
}
