import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ACRButton from '../ACRButton';
import { incidentsContext } from '../../contexts/IncidentsContext';

export default function UnmetNeeds({ incidentIndex }) {
  const { incidents } = useContext(incidentsContext);

  return (
    <div>
    {incidents[incidentIndex].urlName == 'helene' ? (
      <Grid
        container
        xs={12}
        alignSelf=''
        justifyContent='center'
        sx={{
          height: 400,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          bgcolor: '#D0EDFC!important',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
      >
        <Grid item justifySelf='center'>
              <>
                <Typography justifyContent='center' variant='h4' pl={2} pr={2} pb={2}>
                Report Unmet Needs Here:{' '}
                </Typography>
                <ACRButton
                  text='Unmet Needs'
                  link='https://forms.monday.com/forms/ddbd8362337ac0c181b6e6a61581ea3a?r=use1' 
                  size='jumbo'
                  externalLink={true}
                />
                <Typography variant='h4' pl={2} pr={2} pt={6} pb={2}>
                Reporte Daños Insatisfechos Aquí :{' '}
                </Typography>
                <ACRButton
                  text='Reporte Necesidades'
                  link='https://wkf.ms/3ywJB0s'
                  size='jumbo'
                  externalLink={true}
                />
              </>
        </Grid>
      </Grid>
    ) : (
      <></>
    )}
    </div>
  );
}